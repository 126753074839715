.faq-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.faq-list {
    border-top: 1px solid #ddd;
}

.faq-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.faq-question {
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question.active {
    font-weight: bold;
}

.faq-answer {
    margin-top: 10px;
    font-size: 16px;
    color: #555;
}